import { createWebHistory, createRouter } from "vue-router";
const routes = [
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: () => import("./components/Home"),
  },
  {
    path: "/appartement",
    name: "appartement",
    component: () => import("./components/Appartement"),
  },
  {
    path: "/neem-een-kijkje",
    name: "neem-een-kijkje",
    component: () => import("./components/Neem-een-kijkje.vue"),
  },
  {
    path: "/omgeving",
    name: "omgeving",
    component: () => import("./components/Omgeving"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("./components/Contact"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
export default router;
