<template>
  <div id="app">
    <head>
      <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />
    </head>
    <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-dark">
      <div class="container-fluid">
        <button
          class="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler">
          <router-link to="/" class="navbar-brand">Nostalgisch schuurappartement Buitenlust</router-link>
          <div class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link to="/home" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/appartement" class="nav-link">Het appartement</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/neem-een-kijkje" class="nav-link">Neem een kijkje</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/omgeving" class="nav-link">De omgeving</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Info & contact</router-link>
            </li>
          </div>
        </div>
      </div>
    </nav>
    <div>
      <router-view />
    </div>
  </div>
  <footer class="bg-dark text-center mt-5">
    <div class="navbar-nav w-100">
      <div class="row col-md-12 pt-2 d-flex justify-content-center">
        <div class="col-lg-2 col-md-3 col-sm-6 col-12">
          <p class="lead">
            <router-link to="/home" class="nav-link text-light"> <i class="fas fa-home text-light"></i> Home </router-link>
          </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-12">
          <p class="lead">
            <router-link to="/appartement" class="nav-link text-light">
              <i class="fas fa-building"></i> Het appartement
            </router-link>
          </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-12">
          <p class="lead">
            <router-link to="/neem-een-kijkje" class="nav-link text-light">
              <i class="fas fa-images"></i> Neem een kijkje
            </router-link>
          </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-12">
          <p class="lead">
            <router-link to="/omgeving" class="nav-link text-light"> <i class="fas fa-tree"></i> De omgeving </router-link>
          </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-12">
          <p class="lead">
            <router-link to="/contact" class="nav-link text-light"> <i class="fas fa-phone"></i> Info & contact </router-link>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app",
};
</script>
<style>
.html,
body {
  width: 100%;
  padding: 0;
}
.zooming-image {
  transition: transform 0.6s;
}
.zooming-image:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
</style>
